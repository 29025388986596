.error{
    color: red;
    margin-top: 0px;
}

.LoginForm{
    overflow: auto;
}

.label_password{
    margin-right: 14px;
}

.log_label{
    padding-right: 10px;
    
}

.button_container{
    display: flex;
    justify-content: center;
}

button{
    margin-top: 15px;
    font-size: 14px;
    text-decoration: none;
    color: black;
    height: 30px;
    background-color: rgb(252, 186, 65);
}

.log_button2{
    margin-left: 15px;
}

.log_username{
    width: 100px;
}
.log_password{
    width: 100px;
}
input{
    background-color:white;
    margin: 5px;
    width: 150px;
    height: 21px;
    border: 1px orange solid;
    }

    @media (min-width: 600px){
        .log_button{
            font-size: 20px;
        }
    }