.registration_container{
    text-align: center;
}

.label_reg{
   margin-right: 15px;
}

.label_email{
    margin-right: 45px;
}
.label_username{
    margin-right: 5px;
}
.reg_button2{
    margin-left: 20px;
}
.input_container{
    margin-top: 40px;
}
.button_container{
    margin-top: 40px;
}
