.label_addprod{
    margin-right: 15px;
 }
 
 .label_prodname{
     margin-right: 80px;
 }
 .label_price{
     margin-right: 205px;
 }
 .label_inventory{
     margin-right: 115px;
 }
 .reg_button2{
     margin-left: 20px;
 }
 .input_container{
     margin-top: 40px;
 }
 .button_container{
     margin-top: 40px;
 }

 .add_button2{
     margin-left: 15px;
 }
 .addProd_container{
     text-align: center;
 }
 .label_image{
     margin-right: 100px;
 }
 .label_description{
     margin-right: 160px;
 }
 .select-category{
     
     display: inline-block;
     
 }
 .label_category{
     margin-right: 170px;
 }
 .select-category select{
    
    background-color:white;
    margin: 5px;
    width: 150px;
    height: 21px;
    border: 1px orange solid;
    cursor: pointer;
    
 }
 
 

 @media(max-width: 406px){
     .addProd_container{
         display: flex;
         flex-direction: column;

     }
     .label_addprod{
        display: block;
        margin: 0px;
     }
 }