.login-container{
    text-align: center;
    margin-top: 0px;
    border: lightgray solid .5px;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    padding-bottom: 30px;
    overflow: auto;
}

.login_title{
    text-align: center;
}
.LoginPage{
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: white;
  width: 100%;
  max-width: 70%;
  height: 90%;
  padding-top: 50px;
}



@media (min-width: 600px){
    .login-container{
        margin-top: 50px;
        width: 100%;
        max-width: 300px;
        height: 220px;
        
        
    }
    .LoginPage{
        width: 100%;
        max-width: 70%;
        
      }
}



@media (min-width: 1200px){
    .LoginPage{
       
        width: 100%;
        max-width: 60%;
       
        
      }
}
@media (min-width: 1500px){
    .LoginPage{
       
        width: 100%;
        max-width: 50%;
        
        
        
      }
}
@media (min-width: 1800px){
    .LoginPage{
       
        width: 100%;
        max-width: 45%;
        
        
      }
}