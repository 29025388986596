
.homenav_container{
    background: rgb(33, 33, 59);
   
}
.home_nav{
    width: 100%;
    margin: 20px;
    font-size: 16px;
    text-decoration: none;
    color: White;
    font-weight: bolder;
   
}

.link_title1{
    text-decoration: initial;
    color: white;
}
.title1{
   color: White;
   font-size: 25px;
   margin: 0px;
}
.title2{
    color: white;
    font-size: 20px;
    margin:0px;
}

.header_container{
    margin: 0px;
    padding: 0px;
}

.homelink_container{
    display: none;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.header{ 
    padding: 10px;
    color: white;
    overflow-wrap:break-word ; 
    display: flex;
    justify-content: space-between;
    
}

#header_input{
    width: 492px;
    height: 21px;
    padding: 14px;
    padding-top: 15px;
    line-height: 15px;
    font-size: 15px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    vertical-align: middle;
    margin-right: 0px;
    
}

.submit-input{
   all: initial;
   cursor: pointer;
   border-top-right-radius: 3px;
   border-bottom-right-radius: 3px;
   color: white;
   background-color: orange;
   padding: 5px;
   line-height: 15px;
   height: 21px;
   
  
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    
    
  }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  padding-right: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: orange;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  padding: 5px;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  
}

.menu-item{
    text-decoration: none;
    color: white;
    padding: 5px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}



@media (min-width: 950px){
    .bm-burger-button {
       display: none;
      }
}

@media (min-width: 950px){
    .homelink_container{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
}

@media (max-width: 950px){
    
    .home_nav{
        font-size: 14px;
    } 
    .title2{
        display: none;
    }
    
    .title1{
        color: White;
        font-size: 20px;
        margin: 0px;
     }
     #header_input{
        width: 292px;
        height: 21px;
        padding: 14px;
        padding-top: 15px;
        line-height: 15px;
        font-size: 15px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        vertical-align: middle;
        margin-right: 0px;
        
    }
    
    .submit-input{
       all: initial;
       cursor: pointer;
       border-top-right-radius: 3px;
       border-bottom-right-radius: 3px;
       color: white;
       background-color: orange;
       padding: 5px;
       line-height: 15px;
       height: 21px;
       
      
    }
}


@media (max-width: 570px){
    .bm-burger-button {
        width: 30px;
        height: 24px;
       }
    
     #header_input{
        width: 150px;
        height: 21px;
        padding: 14px;
        padding-top: 15px;
        line-height: 15px;
        font-size: 15px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        vertical-align: middle;
        margin-right: 0px;
        
    }
    
    .submit-input{
       all: initial;
       cursor: pointer;
       border-top-right-radius: 3px;
       border-bottom-right-radius: 3px;
       color: white;
       background-color: orange;
       padding: 5px;
       line-height: 15px;
       height: 21px;
       
      
    }
}

@media (max-width: 402px){
    .header{ 
        
        color: white;
        overflow-wrap:break-word ; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        
    }
    .bm-menu {
        position: absolute;
        right: 0px;
        width: 100%;
        max-width: 60%;
        font-size: 16px;
        
      }
    .bm-burger-button {
        
        position: absolute;
        top: 5px;
        right: 0px;
        width: 23px;
        height: 17px;
        float: right;
       }

       /* .title1{
        position: absolute;
        top: 3px;
        color: White;
        font-size: 10px;
        margin: 0px;
     } */
    
     #header_input{
        width: 120px;
        height: 10px;
        padding: 14px;
        padding-top: 10px;
        line-height: 15px;
        font-size: 15px;
        margin-top: 5px;
       
    }
    
    .submit-input{
       
       cursor: pointer;
       border-top-right-radius: 3px;
       border-bottom-right-radius: 3px;
       color: white;
       background-color: orange;
       padding: 5px;
       line-height: 15px;
       height: 16px;
        margin-top: 10px;
      
    }
}

 

  
  
