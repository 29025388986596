.mySearch_container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 30px;
    justify-content: space-between;
    padding: 5px;
}

#myHeader_input{
    all: initial;
    width: 300px;
    height: 25px;
    border: gray 1px solid;
    margin-right: 10px;
}

.mySubmit-input{
    all: initial;
    background-color: gray;
    border-radius: 5px;
    padding: 5px;
    vertical-align: middle;
    color: white;
    font-size: bold;
    font-weight: 400px;
}
.title_your{
    font-size: 28px;
}

@media (max-width: 770px){
    #myHeader_input{
        width: 150px;
        margin-top: 20px;
    }
    .mySearch_container{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
    }
}