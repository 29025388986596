*{
  box-sizing: border-box;
}

body{
  margin:0px;
}











