/* .product_item_container{
    
    border: black solid 1px;
    width: 33%;
    height: 210px;
    font-size: 12px;
    margin: 5px;
    
    overflow-wrap: break-word;
   
} */

.product_item_container{
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-color: white;
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    overflow: auto;
    padding-top: 20px;
    margin: 5px;
    padding: 20px 0 15px 0;
    box-sizing: border-box;
    display: block;
    width: 385px;
    font-size: 14px;
    
    
    
    text-align: center;
    
}
.productItem_h2{
    text-transform: uppercase;
}
.border{
    border-right: solid lightgray 1px;
}
a.prod_links{
    
    text-decoration: none;
    color: #0066c0;
    font-weight: 900;
    transform: translate(-50%, -50%);
    
    
}
.price{
    color: red;
}
.prod_img{
   height: 245px;
   min-height: 245px;
    
}

.item_button{
    font-size: 12px;
    margin-bottom: 5px;

}
.item_button2{
    margin-left: 10px;
}
a.ProductItem_Buy{
    font-size: 20px;
    text-decoration: none;
    color: black;
    height: 30px;
    background-color: rgb(252, 186, 65);
    padding: 5px;
    font-size: 18px;
}




