

.home_p{
    overflow-wrap: break-word;
    margin-right: auto;
    margin-left:auto;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    max-width: 470px;
   
}


.logo_container{
    background-image: url("https://images.squarespace-cdn.com/content/v1/58684f993e00be540c4558b2/1520551249322-AX080PAFE6OKCPM1TKF3/ke17ZwdGBToddI8pDm48kHZMQK7-cUDXoeYsd9TDuyJZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpxLqs-orLxTq53fW5nFCU4KlkU7F-RWWQXdAICrz_TaER9taHYgrah63HCvWBP3oBg/Holistic+Health");
    background-size: 450px;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 50px;
    padding: 5px;
    text-align: center;
    height: 300px;
}

.prodSale_container{
    margin-top: 280px;
    text-align: left;
    padding-left: 20px;
}
.homeItem_container{
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.no_products{
    margin-top: 50px;
    font-size: 25px;
    color: red;
    font-weight: bold;
}


@media (max-width: 859px){
    .homeItem_container{
        margin: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
   


 
  
