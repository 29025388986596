.title_prod{
    text-align: center;
}
.prodButton_container{
    display: flex;
    justify-content: center;
}
.prod_button2{
    margin-left: 15px;
    margin-left: auto;
    margin-right: auto;
}
.prodpage_container{
    background-repeat: no-repeat;
    background-color: white;
    margin-bottom: 100px;
    margin-top: 0;
    padding-top: 20px;
    margin: 5px;
    padding: 20px 0 15px 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow: auto;
    
}


.Prod_info{
    display: inline-block;
    margin-left: 50px;
    max-width: 400px;
    padding-left: 20px;
    margin-left: 0px;
    text-align: initial;
}
.seller-container{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    padding-left: 20px;
    font-size: 12px;
    overflow-wrap: wrap; 
    overflow: auto;
}
.seller-email{
    padding-left: 5px;
    margin-left: 5px;
    
}
@media(max-width:805px){
    .prod_img{
        height: 150px;
        min-height: 150px;
    }
    .Prod_info{
        font-size: 14px;
        margin-left: 0px;
    }
}
@media(max-width: 230px){
    .seller-container{
        display: flex;
        flex-direction: column;
        align-content: center;
       
       
    }
    .seller-email{
        padding-left: 0px;
        margin-left: 0px;
        
    }
}